import { useUserStore } from './user.store'

import { userMeGet } from '../api/userMeGet.api'

export async function meLoad() {
  const store = useUserStore()

  const { userData, response } = await userMeGet()
  if (!userData) return response

  store.meLoaded = true
  store.me = userData

  return response
}
