import type { UserCategory, UserRole, UserGender, UserAvatar, User } from '../types'

type UserApiMeResponseUserAvatar = {
  aspect: number // игнорировать
  id: number
  type: 1 // игнорировать
  urlF?: string // origin
  urlS?: string // medium 200x200
  urlB?: string // big 800x800
  urlO?: string //
  urlT?: string // small 70x70
}

type ApiResponse = {
  instaEnabled: boolean
  events: boolean
  user: {
    id: number
    about: string | null
    name: string | null
    age: number | null
    partnerId: number
    gender: 'Male' | 'Female'
    email: string
    avatar: UserApiMeResponseUserAvatar | null
    locale: string
    isTest: boolean
    isVip: boolean
    role: number
    roles: UserRole[]
    hasPurchase: boolean
    category: UserCategory
    locationCountryCode: string
    emailConfirmed: boolean
    finishPreRegistration: boolean
    approvedTerms: boolean
    approvedVideochatRules: boolean
    agencyId: number
    streamerStatus: boolean
    testFeatures: string[]
    userTests: string[]
    showBlurInRandom: boolean
  }
}

const API_REQUEST_PATH = '/user/me'

function mapper(dto: ApiResponse) {
  const data = dto.user

  let gender: UserGender = ''
  switch (data.gender) {
    case 'Male':
      gender = 'male'
      break
    case 'Female':
      gender = 'female'
      break
  }

  let avatar: UserAvatar | null = null
  if (data.avatar) {
    avatar = {
      id: data.avatar.id,
      src: data.avatar.urlS || data.avatar.urlF || '',
    }
  }

  const roles = data?.roles || []

  return {
    id: data.id,
    name: data.name,
    about: data.about || '',
    age: data.age,
    partnerId: data.partnerId,
    gender,
    email: data.email,
    avatar,
    locale: data.locale,
    emailConfirmed: data.emailConfirmed,
    finishPreRegistration: !roles.includes('APP_PREREGISTER_USER'),
    approvedTerms: data.approvedTerms || false,
    approvedVideochatRules: data.approvedVideochatRules || false,
    anonymous: roles.includes('APP_PREREGISTER_USER'),
    agencyId: data.agencyId,
    role: data.role,
    roles,
    isSubscriber: roles.includes('APP_SUBSCRIBER'),
    isModerator: roles.includes('APP_MODERATOR'),
    isTest: data.isTest,
    isVip: data.isVip,
    category: data.category,
    hasPurchase: data.hasPurchase,
    streamerStatus: data.streamerStatus,
    testFeatures: data.testFeatures,
    userTests: data.userTests,
    showBlurInRandom: data.showBlurInRandom || false,
  }
}

export async function userMeGet() {
  const response = await useApiApp<ApiResponse>(API_REQUEST_PATH)
  const userData: User | null = response.isOk && response.data ? mapper(response.data) : null
  return { userData, response }
}
